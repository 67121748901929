import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CardSection from './CardSection'

function CardListSection({ section_title }) {
    const [promotions, setPromotions] = useState([]);

    useEffect(() => {
        const fetchPromotions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/promociones`);
                setPromotions(response.data);
            } catch (error) {
                console.error('Error fetching promotions:', error);
            }
        };

        fetchPromotions();
    }, []);

    return (
        <>
            <div className="relative bg-black px-4 pb-32 sm:px-6 lg:px-8 lg:pb-72 -mt-20">
                <div className="absolute inset-0">
                    <div className="h-1/3 bg-black sm:h-2/3" />
                </div>

                <div className='relative z-10'>
                    <div className='text-white  bg-opacity-90 bg-black flex justify-between items-center'>
                        <div className='flex-grow pl-5'>
                            <h2 className='text-3xl font-bold relative mt-20 after:content-[""] after:block after:border-b-4 after:border-red-600 after:w-[5%]'>{section_title}</h2>

                            <br />
                            <p className='text mt-2'>
                                Revisa todas nuestras promociones<br />
                                y mantente al dia de lo que tenemos para ti.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="relative z-0 mx-auto mt-14 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
                    {promotions.map((promo, index) => (
                        <CardSection key={index} data={promo} index={index} />
                    ))}
                </div>

            </div>
        </>
    )
}

export default CardListSection
