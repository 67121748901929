import logo1_img from '../../../assets/img/Home-CardSection/Imagen1-1.png'
import logo2_img from '../../../assets/img/Home-CardSection/Imagen1-2.png'
import logo3_img from '../../../assets/img/Home-CardSection/Imagen3.png'


const posts_software = [
    {
        title: 'Promo “Real Magic” – KOA',
        img: logo1_img,
        brands: ' CCoca-Cola original®, Coca-Cola sin azúcar®, Coca-Cola light®, Fanta®, Fanta sin azúcar®, Fanta pomelo®, Fanta frutilla®, Fanta piña®, Sprite®, Sprite sin azúcar®, Inca Kola®, Nordic Ginger Ale®, Nordic Ginger Ale Zero',
        audience: 'Consumidores',
        duration: '01 de febrero de 2024 y hasta el 31 de marzo de 2024',
        href: '#',
        category: { name: 'Article', href: '#' },
        description:
            'NOMBRE DE LA BASE: Promo “Real Magic” – KOA MARCA: CCoca-Cola original®, Coca-Cola sin azúcar®, Coca-Cola light®, Fanta®, Fanta sin azúcar®, Fanta pomelo®, Fanta frutilla®, Fanta piña®, Sprite®, Sprite sin azúcar®, Inca Kola®, Nordic Ginger Ale®, Nordic Ginger Ale Zero. PÚBLICO: Consumidores DURACIÓN: 01 de febrero de 2024 y hasta el 31 de marzo de 2024 […]',
        date: 'Sep 28, 2020',
        datetime: '2020-03-16',
        imageUrl:
            'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
        readingTime: '6 min',
        author: {
            name: 'Roel Aufderehar',
            href: '#',
            imageUrl:
                'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    {
        title: 'PROMO PET 250ml $500',
        href: '#',
        img: logo2_img,
        brands: ' Coca-Cola Original, Coca-Cola Sin azúcar, Fanta, Sprite',
        audience: 'Consumidor final',
        duration: '29 de enero de 2024 y hasta el 29 de abril de 2024',
        category: { name: 'Video', href: '#' },
        description:
            'NOMBRE DE LA PROMOCIÓN: PROMO PET250ml $500 MARCA: Coca-Cola Original, Coca-Cola Sin azúcar, Fanta, Sprite PÚBLICO: Consumidor final DURACIÓN: 29 de enero de 2024 y hasta el 29 de abril de 2024',
        date: 'Sep 28, 2020',
        datetime: '2020-03-10',
        imageUrl:
            'https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
        readingTime: '4 min',
        author: {
            name: 'Brenna Goyette',
            href: '#',
            imageUrl:
                'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    {
        title: 'RP 3 litros Coca-Cola a $2.400',
        href: '#',
        img: logo3_img,
        brands: ' Coca-Cola Original y Coca-Cola Sin azúcar',
        audience: 'Consumidor final',
        duration: '1 de octubre de 2023 y hasta el 31 de diciembre de 2023',
        category: { name: 'Case Study', href: '#' },
        description:
            'NOMBRE DE LA PROMOCIÓN: RP 3 litros Coca-Cola a $2.400 MARCA: Coca-Cola Original y Coca-Cola Sin azúcar PÚBLICO: Consumidor final. DURACIÓN: 1 de octubre de 2023 y hasta el 31 de diciembre de 2023',
        date: 'Sep 28, 2020',
        datetime: '2020-02-12',
        imageUrl:
            'https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
        readingTime: '11 min',
        author: {
            name: 'Daniela Metz',
            href: '#',
            imageUrl:
                'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
]

export default posts_software