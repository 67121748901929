import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import '../src/styles/index.css';
import 'react-slideshow-image/dist/styles.css'

import Error404 from './containers/errors/Error404';
import Home from './containers/pages/Home';
import ComoParticipar from './containers/pages/ComoParticipar';
import Catalogo from './containers/pages/Catalogo';
import PromocionesVigentes from './containers/pages/PromocionesVigentes';
import ProductDetail from './components/catalogo/ProductDetail';
import products from './components/catalogo/data/data';
import posts_software from './components/promociones-vigentes/data/data';
import PromocionDetalle from './components/promociones-vigentes/PromocionDetail';
import NecesitasAyuda from './containers/pages/NecesitasAyuda';
import MiCuenta from './containers/pages/MiCuenta';
import SignIn from './containers/pages/SignIn';
import ProtectedRoute from './containers/auth/ProtectedRoutes';
import Noticias from './containers/pages/Noticias'

function App() {
  return (
    <Router>
      <Routes>
        {/* Error Display */}
        <Route path="*" element={<Error404 />} />
        {/* Views Display */}
        <Route path="/" element={<SignIn />} />
        <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/como_participar" element={<ProtectedRoute><ComoParticipar /></ProtectedRoute>} />
        <Route path="/catalogo" element={<ProtectedRoute><Catalogo /></ProtectedRoute>} />
        <Route path="/promociones_vigentes" element={<ProtectedRoute><PromocionesVigentes /></ProtectedRoute>} />
        <Route path="/necesitas_ayuda" element={<ProtectedRoute><NecesitasAyuda /></ProtectedRoute>} />
        <Route path="/mi_cuenta" element={<ProtectedRoute><MiCuenta /></ProtectedRoute>} />
        <Route path="/noticias" element={<ProtectedRoute><Noticias /></ProtectedRoute>} />
        {/* Ruta para el detalle de producto */}
        <Route path="/detail/:id" element={<ProtectedRoute><ProductDetail products={products} /></ProtectedRoute>} />
        {/* Ruta para los detalles de la promoción */}
        <Route path="/promociones_vigentes/detalle/:id" element={<ProtectedRoute><PromocionDetalle posts={posts_software} /></ProtectedRoute>} />
      </Routes>
    </Router>
  );
}

export default App;
