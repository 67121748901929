import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom'; // Asegúrate de importar Link
import Layout from '../../hocs/layout/Layout';
import Navbar from '../../components/navigation/Navbar';
import Footer from '../../components/navigation/Footer';
import postsSoftware from './data/data';

function PromocionDetalle() {
    const { id } = useParams(); // id ahora es el index que pasaste en la URL
    const promocion = postsSoftware[parseInt(id, 10)]; // Asegúrate de convertir el id a número si es necesario

    useEffect(() => {
        window.scrollTo(0, 0); // Esto desplaza el scroll hacia la parte superior de la página
    }, []);

    if (!promocion) {
        return <div className="text-center text-lg font-semibold mt-4">Promoción no encontrada</div>;
    }
    return (
        <Layout>
            <Navbar />
            <div className="max-w-5xl mx-auto mt-52 p-5 bg-white rounded shadow-lg">
                <div className="flex justify-between items-center mb-5">
                    <h1 className="text-3xl font-bold text-gray-700">{promocion.title}</h1>
                    <Link to="/" className="text-red-600 font-semibold hover:text-red-700 transition-colors duration-300">
                        Volver
                    </Link>
                </div>
                <hr className="mb-5 border-red-500" />
                <div className="grid md:grid-cols-3 gap-5">
                    <img src={promocion.img} alt={promocion.title} className="col-span-1 rounded shadow-md" />
                    <div className="col-span-2">
                        <h2 className="text-xl font-semibold text-gray-800 mb-2">NOMBRE DE LA BASE: {promocion.title}</h2>
                        <p className="text-gray-600 mb-3">
                            <span className="font-bold">MARCA:</span>
                            {promocion.brands /* ? promocion.brands.join(', ') : 'No especificado' */}
                        </p>
                        <p className="text-gray-600 mb-3">
                            <span className="font-bold">PÚBLICO:</span> {promocion.audience}
                        </p>
                        <p className="text-gray-600">
                            <span className="font-bold">DURACIÓN:</span> {promocion.duration}
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    );
}

export default PromocionDetalle;
