import { useEffect } from "react"
import Navbar from '../../components/navigation/Navbar'
import Footer from '../../components/navigation/Footer'
import Layout from '../../hocs/layout/Layout'
import ContactForm from "../../components/necesitas-ayuda/ContactForm"

function NecesitasAyuda() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Layout>
            <Navbar />
            <div data-scroll-section className="pt-28">
                <ContactForm />
            </div>
            <Footer />
        </Layout>
    )
}

export default NecesitasAyuda