// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import logo_donbife from '../../assets/img/Home-CardCarousel/02_donbife.png'
// import logo_buinzoo from '../../assets/img/Home-CardCarousel/03_buinzoo.png'
// import logo_juicylucy from '../../assets/img/Home-CardCarousel/05_juicylucy.png'
// import logo_santabrasa from '../../assets/img/Home-CardCarousel/04_santabrasa.png'
// import logo_elguaranguito from '../../assets/img/Home-CardCarousel/01_elguaranguito.png'
// import logo_giftcardcencosud from '../../assets/img/Home-CardCarousel/Gift-Card-Cencosud-200.000.png'
// import logo_giftcardsmu from '../../assets/img/Home-CardCarousel/Gift-Card-Smu.png'



// const blog = [
//     {
//         id: 1,
//         title: 'Gift Card Don Bife',
//         imageSrc: logo_donbife,
//         description:
//             'Desde 5.000 Litros',
//     },
//     {
//         id: 2,
//         title: 'Entrada para el Buin Zoo',
//         imageSrc: logo_buinzoo,
//         description:
//             '8.184 Litros',
//     },
//     {
//         id: 3,
//         title: 'Gift Card Juicy Lucy',
//         imageSrc: logo_juicylucy,
//         description:
//             'Desde 5.000 Litros',
//     },
//     {
//         id: 4,
//         title:
//             'Gift Card Santa Brasa',
//         imageSrc: logo_santabrasa,
//         description:
//             'Desde 5.000 Litros',
//     },
//     {
//         id: 5,
//         title:
//             'Gift Card EL Guaranguito',
//         imageSrc: logo_elguaranguito,
//         description:
//             'Desde 5.000 Litros',
//     },
//     {
//         id: 6,
//         title:
//             'Gift Card Cencosud',
//         imageSrc: logo_giftcardcencosud,
//         description:
//             'Desde 5.000 Litros',
//     },
//     {
//         id: 7,
//         title:
//             'Gift Card SMU',
//         imageSrc: logo_giftcardsmu,
//         description:
//             'Desde 5.000 Litros',
//     },
// ];

// function WhiteCardCarousel() {
//     const [isPaused, setIsPaused] = useState(false);
//     const carouselBlogs = [...blog, ...blog];

//     return (


//         <div className='relative bg-gray-90'>
//             {/* Este div contendrá el título y la descripción y estará por encima del carrusel */}
//             <div className="mx-auto max-w-full py-24 sm:px-2 sm:py-32 lg:px-4">
//                 <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
//                     <div className="max-w-3xl">
//                         <h2 className='text-3xl font-bold relative mt-20 after:content-[""] after:block after:border-b-4 after:border-red-600 after:w-[8%]'>
//                             Catalogo
//                         </h2>
//                         {/* <p className='text mt-2'>
//                         Revisa todo el catálogo y conoce los productos<br />
//                         que puedes obtener con tus Litros acumulados.
//                     </p> */}
//                     </div>
//                     {/* <div className='pr-5'>
//                     <button className='bg-red-600 text-white font-medium mt-12 py-2 px-4 rounded-md hover:bg-red-500 transition duration-300'>
//                         Ver Más &gt;
//                     </button>
//                 </div> */}
//                 </div>


//                 {/* Este div contendrá el carousel */}
//                 <div className='card-carousel py-28 flex overflow-auto pt-[80px] mt-4'>
//                     <div className='my-5 mx-auto max-w-full'>
//                         <div className='flex overflow-hidden min-w-full'>
//                             <div className={`flex whitespace-nowrap animate-scroll ${isPaused ? 'paused' : ''}`}
//                                 onMouseEnter={() => setIsPaused(true)}
//                                 onMouseLeave={() => setIsPaused(false)}>
//                                 {carouselBlogs.map((item, index) => (
//                                     <Link to={`/detail/${item.id}`} key={index} className='h-full w-[400px] m-2 flex-shrink-0 cursor-pointer'>
//                                         <div className='rounded-3xl overflow-hidden mb-4 relative' style={{ width: '400px', height: '200px' }}>
//                                             <img src={item.imageSrc} alt={item.title} className="object-cover w-full h-full" />
//                                         </div>
//                                         <div className='px-4 flex gap-4'>
//                                             <div className='flex flex-col gap-2 w-full'>
//                                                 <h3 className='text-lg font-bold text-slate-700 leading-7 whitespace-normal'>
//                                                     {item.title}
//                                                 </h3>
//                                                 <p className='text-sm text-slate-500 font-semibold'>
//                                                     {item.description}
//                                                 </p>
//                                             </div>
//                                         </div>
//                                     </Link>
//                                 ))}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default WhiteCardCarousel

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const webUrl = process.env.REACT_APP_WEB_URL;

const WhiteCardCarousel = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${apiUrl}/products`);
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    return (
        <div className='relative bg-gray-90'>
            <div className="mx-auto max-w-full py-24 sm:px-2 sm:py-32 lg:px-4">
                <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
                    <div className="max-w-3xl">
                        <h2 className='text-3xl font-bold relative mt-20 after:content-[""] after:block after:border-b-4 after:border-red-600 after:w-[8%]'>
                            Catálogo
                        </h2>
                    </div>
                </div>
                <div className='py-28 pt-[80px] mt-4'>
                    <div className='my-5 mx-auto max-w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
                        {products.map((item, index) => (
                            <Link to={`/detail/${item._id}`} key={index} className='block'>
                                <div className='p-4 border border-gray-300 rounded-lg hover:shadow-lg transition-shadow duration-300'>
                                    <div className='w-full h-48 mb-4'>
                                        <img src={`${webUrl}/imagenes/${item.image}`} alt={item.name} className="object-cover w-full h-full rounded-lg" />
                                    </div>
                                    <div>
                                        <h3 className='text-lg font-bold text-slate-700 leading-7'>
                                            {item.name}
                                        </h3>
                                        <p className='text-sm text-slate-500 font-semibold'>
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhiteCardCarousel;


