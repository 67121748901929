import { useEffect } from "react"
import ComoParticipar from '../../components/como-participar/ComoParticipar'
import Navbar from '../../components/navigation/Navbar'
import Footer from '../../components/navigation/Footer'
import Layout from '../../hocs/layout/Layout'

function ComoParticiparPage() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      return (
        <Layout>
          <Navbar />
          <div data-scroll-section className="pt-28">
           <ComoParticipar /> 
          </div>
          <Footer />
        </Layout>
      )
}

export default ComoParticiparPage