import { useEffect } from "react"
import MiCuenta from '../../components/mi-cuenta/MiCuenta'
import Navbar from '../../components/navigation/Navbar'
import Footer from '../../components/navigation/Footer'
import Layout from '../../hocs/layout/Layout'

function MiCuentaPage() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Layout>
            <Navbar />
            <div data-scroll-section className="pt-28">
                <MiCuenta />
            </div>
            <Footer />
        </Layout>
    )
}

export default MiCuentaPage