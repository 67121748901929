
// import React, { useState } from 'react'
// import { LockClosedIcon } from '@heroicons/react/20/solid'
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { jwtDecode } from 'jwt-decode';
// import logo from '../../assets/img/Navbar/logo_litros.png'

// const apiUrl = process.env.REACT_APP_API_URL;
// console.log("API URL:", apiUrl);

// function SignIn() {

//     const navigate = useNavigate();

//     const [formData, setFormData] = useState({
//         rut: '',
//         password: ''
//     })
//     const [error, setError] = useState('');

//     const { rut, password } = formData;

//     const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });


//     const onSubmit = async e => {
//         e.preventDefault();
//         setError(''); // Reset error state

//         try {
//             const res = await axios.post(`${apiUrl}/user/login`, formData);
//             const token = res.data.token;
//             if (!token) {
//                 throw new Error('No token received from the server');
//             }

//             localStorage.setItem('token', token); // Guarda el token en el localStorage


//             const decodedToken = jwtDecode(token); // Decodifica el token
//             console.log("Decoded Token:", decodedToken); // Imprimir el token decodificado

//             const userId = decodedToken.user._id; // Obtiene el userId del token decodificado
//             if (!userId) {
//                 throw new Error('User ID not found in token');
//             }

//             localStorage.setItem('userId', userId); // Guarda el userId en el localStorage

//             navigate('/home');
//         } catch (err) {
//             console.error("Login error:", err); // Imprimir detalles del error completo
//             if (err.response) {
//                 if (err.response.status === 401) {
//                     setError('Invalid rut or password');
//                 } else {
//                     setError('An unexpected error occurred. Please try again later.');
//                 }
//             } else {
//                 setError(err.message);
//             }
//         }
//     };

//     return (
//         <div>
//             {/* Navbar */}
//             <div className="bg-black py-4 flex justify-center">
//                 <img src={logo} alt="Litros de Felicidad" className="h-12" />
//             </div>

//             {/* Sign In Form */}
//             <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-50">
//                 <div className="w-full max-w-md space-y-8">
//                     <div className="text-center">
//                         <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
//                             ¡Bienvenido!
//                         </h2>
//                     </div>
//                     <form onSubmit={e => { onSubmit(e) }} className="mt-8 space-y-6">
//                         <div className="-space-y-px rounded-md shadow-sm">
//                             <div>
//                                 <label htmlFor="rut" className="sr-only">
//                                     Usuario
//                                 </label>
//                                 <input
//                                     id="rut"
//                                     name="rut"
//                                     value={rut}
//                                     onChange={e => onChange(e)}
//                                     type="text"
//                                     required
//                                     className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
//                                     placeholder="Usuario"
//                                 />
//                             </div>
//                             <div>
//                                 <label htmlFor="password" className="sr-only">
//                                     Clave
//                                 </label>
//                                 <input
//                                     id="password"
//                                     name="password"
//                                     value={password}
//                                     onChange={e => onChange(e)}
//                                     type="password"
//                                     required
//                                     className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
//                                     placeholder="Clave"
//                                 />
//                             </div>
//                         </div>

//                         <div className="flex items-center justify-between">
//                             <div className="text-sm">
//                                 <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
//                                     ¿Olvidaste tu Clave?
//                                 </a>
//                             </div>
//                         </div>

//                         <div>
//                             <button
//                                 type="submit"
//                                 className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
//                             >
//                                 <span className="absolute inset-y-0 left-0 flex items-center pl-3">
//                                     <LockClosedIcon className="h-5 w-5 text-red-500 group-hover:text-red-400" aria-hidden="true" />
//                                 </span>
//                                 INGRESAR
//                             </button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default SignIn

import React, { useState } from 'react'
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import swal from 'sweetalert2';
import logo from '../../assets/img/Navbar/logo_litros.png'
import Modal from 'react-modal';

const apiUrl = process.env.REACT_APP_API_URL;
console.log("API URL:", apiUrl);

function SignIn() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ rut: '', password: '' });
    const [error, setError] = useState('');
    const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [passwordReset, setPasswordReset] = useState(false);
    const [forgotPasswordRut, setForgotPasswordRut] = useState('');

    const { rut, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();
        setError(''); // Reset error state

        try {
            const res = await axios.post(`${apiUrl}/user/login`, formData);
            const token = res.data.token;
            if (!token) throw new Error('No token received from the server');

            localStorage.setItem('token', token); // Guarda el token en el localStorage

            const decodedToken = jwtDecode(token); // Decodifica el token
            console.log("Decoded Token:", decodedToken); // Imprimir el token decodificado

            const userId = decodedToken.user._id; // Obtiene el userId del token decodificado
            if (!userId) throw new Error('User ID not found in token');

            localStorage.setItem('userId', userId); // Guarda el userId en el localStorage

            if (decodedToken.user.passwordReset) {
                setPasswordReset(true);
                setIsResetPasswordModalOpen(true);
            } else {
                swal.fire('Success', 'Login exitoso!', 'success');
                navigate('/home');
            }
        } catch (err) {
            console.error("Login error:", err); // Imprimir detalles del error completo
            if (err.response) {
                if (err.response.status === 401) {
                    setError('Invalid rut or password');
                    swal.fire('Error', 'Rut o contraseña incorrectos.', 'error');
                } else {
                    setError('An unexpected error occurred. Please try again later.');
                    swal.fire('Error', 'Ha ocurrido un error inesperado. Porfavor intente mas tarde.', 'error');
                }
            } else {
                setError(err.message);
                swal.fire('Error', err.message, 'error');
            }
        }
    };

    const handleForgotPassword = async () => {
        try {
            await axios.post(`${apiUrl}/user/forgot-password`, { rut: forgotPasswordRut });
            swal.fire('Success', 'Clave provisoria enviada a su correo exitosamente.', 'success');
            setIsForgotPasswordModalOpen(false);
        } catch (error) {
            console.error("Error sending password reset email:", error);
            swal.fire('Error', 'Hubo un error enviando la clave provisoria a su correo.', 'error');
        }
    };

    const handlePasswordChange = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No token found');

            await axios.put(`${apiUrl}/user/pwd`, { password: newPassword }, {
                headers: {
                    'Authorization': token
                }
            });

            setIsResetPasswordModalOpen(false);
            setPasswordReset(false);
            swal.fire('Success', 'Contraseña actualizada exitosamente.', 'success');
            navigate('/home');
        } catch (error) {
            console.error("Error changing password:", error);
            swal.fire('Error', 'Hubo un error cambiando su contraseña.', 'error');
        }
    };

    return (
        <div>
            {/* Navbar */}
            <div className="backgroundred py-4 flex justify-center">
                <img src={logo} alt="Litros de Felicidad" className="h-24" />
            </div>

            {/* Sign In Form */}
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-50">
                <div className="w-full max-w-md space-y-8">
                    <div className="text-center">
                        <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                            ¡Bienvenido!
                        </h2>
                    </div>
                    <form onSubmit={e => onSubmit(e)} className="mt-8 space-y-6">
                    <div className="flex items-center justify-between">
                            <div className="text-sm">
                                <p className="font-xs text-slate-400 ">
                                Estimado usuario:<br></br> para ingresar a la nueva plataforma, favor utiliza tu usuario habitual (mail) o Rut (con guion sin puntos ), y clave: tu Rut con guion sin puntos (luego se te solicitará el cambio).
                                </p>
                            </div>
                        </div>
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                                <label htmlFor="rut" className="sr-only">
                                    Usuario
                                </label>
                                <input
                                    id="rut"
                                    name="rut"
                                    value={rut}
                                    onChange={e => onChange(e)}
                                    type="text"
                                    required
                                    className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="Usuario"
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    Clave
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    value={password}
                                    onChange={e => onChange(e)}
                                    type="password"
                                    required
                                    className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    placeholder="Clave"
                                />
                            </div>
                        </div>
                       
                        <div className="flex items-center justify-between">
                            <div className="text-sm">
                                <a href="#" onClick={() => setIsForgotPasswordModalOpen(true)} className="font-medium text-indigo-600 hover:text-indigo-500">
                                    ¿Olvidaste tu Clave?
                                </a>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <LockClosedIcon className="h-5 w-5 text-red-500 group-hover:text-red-400" aria-hidden="true" />
                                </span>
                                INGRESAR
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/* Modal for Forgot Password */}
            <Modal
                isOpen={isForgotPasswordModalOpen}
                onRequestClose={() => setIsForgotPasswordModalOpen(false)}
                contentLabel="Recuperar Clave"
                className="fixed inset-0 flex items-center justify-center"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            >
                <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
                    <h2 className="text-lg font-bold mb-4">Recuperar Clave</h2>
                    <div className="mb-4">
                        <label className="block text-sm font-bold text-gray-700">Ingrese su RUT</label>
                        <input
                            type="text"
                            value={forgotPasswordRut}
                            onChange={(e) => setForgotPasswordRut(e.target.value)}
                            className="w-full border-b-2 border-gray-300 focus:border-red-600 focus:outline-none py-1"
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            onClick={() => setIsForgotPasswordModalOpen(false)}
                            className="border border-gray-600 text-gray-600 py-2 px-4 rounded hover:bg-gray-100"
                        >
                            Cancelar
                        </button>
                        <button
                            onClick={handleForgotPassword}
                            className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
                        >
                            Enviar
                        </button>
                    </div>
                </div>
            </Modal>

            {/* Modal for Password Change */}
            <Modal
                isOpen={isResetPasswordModalOpen}
                onRequestClose={() => setIsResetPasswordModalOpen(false)}
                contentLabel="Cambiar Clave"
                className="fixed inset-0 flex items-center justify-center"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            >
                <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
                    <h2 className="text-lg font-bold mb-4">Cambiar Clave</h2>
                    <div className="mb-4">
                        <label className="block text-sm font-bold text-gray-700">Nueva Contraseña</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="w-full border-b-2 border-gray-300 focus:border-red-600 focus:outline-none py-1"
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            onClick={() => setIsResetPasswordModalOpen(false)}
                            className="border border-gray-600 text-gray-600 py-2 px-4 rounded hover:bg-gray-100"
                        >
                            Cancelar
                        </button>
                        <button
                            onClick={handlePasswordChange}
                            className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default SignIn;
