import React from 'react'

const webUrl = process.env.REACT_APP_WEB_URL;


function CardSection({ data, index }) {
    const onMouseEnter = () => {
        const title_element = document.getElementById(`title-${index}`);
        if (title_element) {
            title_element.classList.add('text-red-500');
            title_element.classList.remove('text-gray-900');
        }
    };

    const onMouseLeave = () => {
        const title_element = document.getElementById(`title-${index}`);
        if (title_element) {
            title_element.classList.remove('text-red-500');
            title_element.classList.add('text-gray-900');
        }
    };

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className="w-full relative h-96 lg:h-[200pm] bg-red-700 hover:-translate-y-1 transition duration-300 ease-in-out">
            <div className="w-full">
                <img src={`${webUrl}/imagenes/${data?.imagen}`} alt={data?.titulo || 'Image'} className='w-full h-full object-cover' />
                {/* <h2 className="text-xl font-semibold text-gray-900 pt-8">{data?.title}</h2>
                <p className="text-lg font-regular text-gray-500 pt-4">{data?.description}</p> */}
            </div>
            <div className="absolute bottom-0 left-0 p-8">
                <h2 id={`title-${index}`} className="items-end text-xl font-semibold text-white pt-8"></h2>
            </div>
        </div>
    );
}

export default CardSection;
