import React from 'react'
import { Link } from 'react-router-dom';

const webUrl = process.env.REACT_APP_WEB_URL;

function PromocionesVigentes({ data, index }) {
    const onMouseEnter = () => {
        const title_element = document.getElementById(`title-${index}`);
        if (title_element) {
            title_element.classList.add('text-red-500');
            title_element.classList.remove('text-gray-900');
        }
    };

    const onMouseLeave = () => {
        const title_element = document.getElementById(`title-${index}`);
        if (title_element) {
            title_element.classList.remove('text-red-500');
            title_element.classList.add('text-gray-900');
        }
    };

    return (

        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className="w-full relative bg-red-700 hover:-translate-y-1 transition duration-300 ease-in-out">

            <Link to={`/promociones_vigentes/detalle/${index}`} className='block w-full h-full'>
                {/* Imagen de Fondo */}
                <img src={`${webUrl}/imagenes/${data?.imagen}`} alt={data?.titulo || 'Image'} className='w-full object-cover' style={{ height: '70%' }} />

                {/* Contenedor de Descripción con Fondo Semiclaro */}
                <div className="bg-white bg-opacity-75 text-black p-4" style={{ height: '30%' }}>
                    <h2 id={`title-${index}`} className="text-xl font-semibold">{data?.titulo}</h2>
                    <p className="text-sm mt-2">{data?.marca}</p>
                    <br />
                    <p className="text-sm">{data?.duracion}</p>
                </div>
            </Link>
            <button className="mt-2 bg-red-500 text-white px-3 py-1 rounded">Ver Más</button>

        </div >
    );
}

export default PromocionesVigentes