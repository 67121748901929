// import React from 'react';
// import logo_litros from '../../assets/img/Navbar/logo_litros.png';

// const navigation = {
//   solutions: [
//     { name: 'Marketing', href: '#' },
//     { name: 'Analytics', href: '#' },
//     { name: 'Commerce', href: '#' },
//     { name: 'Insights', href: '#' },
//   ],
//   support: [
//     { name: 'Contacto', href: '/necesitasayuda' },
//   ],
//   company: [
//     { name: '¿Como Participar?', href: '/como_participar' },
//     { name: 'Catálago', href: '/catalogo' },
//     { name: 'Promocione Vigentes', href: '/promociones_vigentes' },
//     { name: 'Noticias', href: '/noticias' },
//   ],
//   legal: [
//     { name: 'Privacidad', href: '#' },
//     { name: 'Terminos', href: '#' },
//   ],
//   social: [
//     {
//       name: 'Facebook',
//       href: '#',
//       icon: (props) => (
//         <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
//           <path
//             fillRule="evenodd"
//             d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
//             clipRule="evenodd"
//           />
//         </svg>
//       ),
//     },
//     {
//       name: 'Instagram',
//       href: '#',
//       icon: (props) => (
//         <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
//           <path
//             fillRule="evenodd"
//             d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
//             clipRule="evenodd"
//           />
//         </svg>
//       ),
//     },
//     {
//       name: 'Twitter',
//       href: '#',
//       icon: (props) => (
//         <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
//           <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
//         </svg>
//       ),
//     },
//   ],
// }

// function Footer() {
//   return (
//     <footer className=" w-full bg-black" aria-labelledby="footer-heading">
//       <h2 id="footer-heading" className="sr-only">
//         Footer
//       </h2>
//       <div className="mx-auto max-w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
//         <div className="xl:grid xl:grid-cols-3 xl:gap-8">
//           <div className="space-y-8 xl:col-span-1">
//             <img
//               src={logo_litros}
//               width={60}
//               height={60}
//               className="ml-8" />
//             <div className="flex space-x-6">
//               {navigation.social.map((item) => (
//                 <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
//                   <span className="sr-only">{item.name}</span>
//                   <item.icon className="h-6 w-6" aria-hidden="true" />
//                 </a>
//               ))}
//             </div>
//           </div>
//           <div className="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
//             <div className="md:grid md:grid-cols-2 md:gap-8">
//               <div>
//                 <h3 className="text-base font-medium text-gray-700">Visitanos</h3>
//                 <ul role="list" className="mt-4 space-y-4">
//                   {navigation.solutions.map((item) => (
//                     <li key={item.name}>
//                       <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
//                         {item.name}
//                       </a>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//               <div className="mt-12 md:mt-0">
//                 <h3 className="text-base font-medium text-gray-700">Contactanos</h3>
//                 <ul role="list" className="mt-4 space-y-4">
//                   {navigation.support.map((item) => (
//                     <li key={item.name}>
//                       <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
//                         {item.name}
//                       </a>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             </div>
//             <div className="md:grid md:grid-cols-2 md:gap-8">
//               <div>
//                 <h3 className="text-base font-medium text-gray-700">Compañia</h3>
//                 <ul role="list" className="mt-4 space-y-4">
//                   {navigation.company.map((item) => (
//                     <li key={item.name}>
//                       <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
//                         {item.name}
//                       </a>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//               <div className="mt-12 md:mt-0">
//                 <h3 className="text-base font-medium text-gray-700">Servicios</h3>
//                 <ul role="list" className="mt-4 space-y-4">
//                   {navigation.legal.map((item) => (
//                     <li key={item.name}>
//                       <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
//                         {item.name}
//                       </a>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="mt-12 border-t border-gray-700 pt-8">
//           <p className="text-base text-gray-400 xl:text-center">&copy; 2024 Litros De Felicidad by DT360 GROUP, Inc. All rights reserved.</p>
//         </div>
//       </div>
//     </footer>
//   )
// }

// export default Footer;

import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import logo_litros from '../../assets/img/Navbar/logo_litros.png';
import logo1 from '../../assets/img/Navbar/logo_cocacola_andina.png';
import logo2 from '../../assets/img/Navbar/logo_diageo.png';
import logo3 from '../../assets/img/Navbar/logo_cooperativa_capel.png';
import logo4 from '../../assets/img/Navbar/logo_abinbev.png';
import logo5 from '../../assets/img/Navbar/logo_guallarauco.png';

const Footer = () => {
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [sucursal, setSucursal] = useState('');
  const [comentario, setComentario] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      nombre,
      email,
      sucursal,
      comentario,
    };

    console.log('Datos enviados a la API:', formData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/formulario`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 201) {
        Swal.fire(
          '¡Enviado!',
          'Tu formulario ha sido enviado con éxito.',
          'success'
        );
        setNombre('');
        setEmail('');
        setSucursal('');
        setComentario('');
      } else {
        console.error('Error al enviar el formulario');
      }
    } catch (error) {
      console.error('Error al enviar el formulario', error);
    }
  };

  return (
    <footer className="w-full bg-black text-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-screen-lg mx-auto p-5 mt-0 rounded-md">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="md:w-1/2 flex flex-col -mt-20 justify-center text-center md:text-left">
            <h3 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight text-white">
              ¿Necesitas <span className="text-red-600">Ayuda?</span>
            </h3>
            <p className="mt-4 leading-7 text-gray-400">
              Estamos atentos a tus consultas, soluciones o comentarios.
            </p>
          </div>
          <form className="bg-black p-10 rounded md:w-1/2 w-full" onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2" htmlFor="grid-nombre">
                  Nombre
                </label>
                <input
                  className="appearance-none block w-full bg-gray-800 text-gray-300 border border-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-700"
                  id="grid-nombre"
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2" htmlFor="grid-sucursal">
                  Sucursal
                </label>
                <input
                  className="appearance-none block w-full bg-gray-800 text-gray-300 border border-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-700"
                  id="grid-sucursal"
                  type="text"
                  placeholder="Sucursal"
                  value={sucursal}
                  onChange={(e) => setSucursal(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2" htmlFor="grid-email">
                  Email
                </label>
                <input
                  className="appearance-none block w-full bg-gray-800 text-gray-300 border border-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-700"
                  id="grid-email"
                  type="email"
                  placeholder="correo@ejemplo.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2" htmlFor="grid-comentario">
                  Comentario
                </label>
                <textarea
                  rows="4"
                  className="appearance-none block w-full bg-gray-800 text-gray-300 border border-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-700"
                  id="grid-comentario"
                  placeholder="Escribe tu comentario"
                  value={comentario}
                  onChange={(e) => setComentario(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="w-full px-3 flex justify-end">
              <button
                className="shadow bg-red-600 hover:bg-red-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded"
                type="submit"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
        <div className="flex justify-around items-center mt-12 border-t border-gray-700 pt-8 flex-wrap">
          <img src={logo1} alt="Coca Cola" className="h-6 mx-2 my-2" />
          <img src={logo2} alt="Diageo" className="h-6 mx-2 my-2" />
          <img src={logo3} alt="Capel" className="h-6 mx-2 my-2" />
          <img src={logo4} alt="Abinbev" className="h-6 mx-2 my-2" />
          <img src={logo_litros} alt="Litros de Felicidad" className="h-8 mx-2 my-2" />
        </div>
        <p className="text-center text-gray-500 mt-8">
          &copy; 2024 Litros De Felicidad. Todos los derechos reservados.
        </p>
      </div>
    </footer>
  );
};

export default Footer;