import logo_donbife from '../../../assets/img/Home-CardCarousel/02_donbife.png'
import logo_buinzoo from '../../../assets/img/Home-CardCarousel/03_buinzoo.png'
import logo_juicylucy from '../../../assets/img/Home-CardCarousel/05_juicylucy.png'
import logo_santabrasa from '../../../assets/img/Home-CardCarousel/04_santabrasa.png'
import logo_elguaranguito from '../../../assets/img/Home-CardCarousel/01_elguaranguito.png'
import logo_giftcardcencosud from '../../../assets/img/Home-CardCarousel/Gift-Card-Cencosud-200.000.png'
import logo_giftcardsmu from '../../../assets/img/Home-CardCarousel/Gift-Card-Smu.png'



const products = [
    {
        id: 1,
        title: 'Gift Card Don Bife',
        imageSrc: logo_donbife,
        description:
            'Desde 5.000 Litros',
    },
    {
        id: 2,
        title: 'Entrada para el Buin Zoo',
        imageSrc: logo_buinzoo,
        description:
            '8.184 Litros',
    },
    {
        id: 3,
        title: 'Gift Card Juicy Lucy',
        imageSrc: logo_juicylucy,
        description:
            'Desde 5.000 Litros',
    },
    {
        id: 4,
        title:
            'Gift Card Santa Brasa',
        imageSrc: logo_santabrasa,
        description:
            'Desde 5.000 Litros',
    },
    {
        id: 5,
        title:
            'Gift Card EL Guaranguito',
        imageSrc: logo_elguaranguito,
        description:
            'Desde 5.000 Litros',
    },
    {
        id: 6,
        title:
            'Gift Card Cencosud',
        imageSrc: logo_giftcardcencosud,
        description:
            'Desde 5.000 Litros',
    },
    {
        id: 7,
        title:
            'Gift Card SMU',
        imageSrc: logo_giftcardsmu,
        description:
            'Desde 5.000 Litros',
    },
];

export default products;