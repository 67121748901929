import icono_carro from '../../assets/img/Home-Incentives/icono_carro.png'
import icono_meta from '../../assets/img/Home-Incentives/icono_meta.png'
import icono_ok from '../../assets/img/Home-Incentives/icono_ok.png'


const incentives = [
  {
    name: 'CUMPLE TU META',
    imageSrc: icono_meta,
    description: "Debes cumplir las metas de los concursos SSD, STILL, Cooperativa CAPEL y DIAGEO que se estén realizando en el mes. Mientras más metas cumplas, más litros acumulas.",
  },
  {
    name: 'ACUMULA TUS LITROS',
    imageSrc: icono_carro,
    description: "Revisa periódicamente los litros que hayas ganado por los concursos. Recuerda que puedes acumular tus litros para canjear el premio que deseas. 1 Litro = $1 Peso",
  },
  {
    name: 'CANJEA TUS LITROS',
    imageSrc: icono_ok,
    description: "Cuando porfin tengas acumulado la cantidad de litros que necesitas canjea tus Litros de felicidad en la sección Catálogo, busca el articulo que mas te guste y disfruta tú premio!",
  },
]

function Incentives() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-full py-24 sm:px-2 sm:py-32 lg:px-4">
        <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
          <div className="max-w-3xl">
            <h2 className="text-4xl font-bold tracking-tight text-red-500">
              Con tan solo seguir estos tres pasos
            </h2>
            <p className="mt-4 text-gray-500">
              te embarcarás en un viaje gratificante que no solo recompensa tu arduo trabajo,
              sino que también hace que cada día en el trabajo sea una oportunidad para ganar.
            </p>
          </div>
          <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
            {incentives.map((incentive) => (
              <div key={incentive.name} className="flex flex-col items-center justify-center text-center">
                <div className="flex items-center justify-center sm:flex-shrink-0">
                  <img className="h-16 w-16" src={incentive.imageSrc} alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-red-500">{incentive.name}</h3>
                  <p className="mt-2 text-sm text-gray-500">{incentive.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Incentives
