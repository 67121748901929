// import React, { useState, useEffect } from 'react';
// import Layout from '../../hocs/layout/Layout';
// import Navbar from '../../components/navigation/Navbar';
// import Footer from '../../components/navigation/Footer';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';

// const apiUrl = process.env.REACT_APP_API_URL;

// const ProductDetail = ({ products }) => {
//     let { id } = useParams();
//     const product = products.find(p => p.id === parseInt(id));
//     const [selectedPoints, setSelectedPoints] = useState('');

//     useEffect(() => {
//         window.scrollTo(0, 0); // Esto desplaza el scroll hacia la parte superior de la página
//     }, []);

//     const handlePointsChange = (e) => {
//         setSelectedPoints(e.target.value);
//     };

//     const handleRedeem = async () => {
//         if (!selectedPoints || selectedPoints === "Seleccione") {
//             alert('Seleccione una cantidad de puntos a canjear');
//             return;
//         }

//         const userId = localStorage.getItem('userId'); // Obtener el userId del localStorage

//         const authToken = localStorage.getItem('token');

//         if (!userId) {
//             alert('Usuario no autenticado');
//             return;
//         }

//         if (!authToken) {
//             alert('Token de autenticación no encontrado');
//             return;
//         }

//         console.log('Token:', authToken);

//         try {
//             console.log('Datos enviados:', {
//                 userId: userId, // Utilizar el userId obtenido del localStorage
//                 productId: product.id,
//                 optionId: selectedPoints,
//                 amount: parseInt(selectedPoints)
//             });

//             const response = await axios.post(`${apiUrl}/points/deduct`, {
//                 userId: userId, // Utilizar el userId obtenido del localStorage
//                 productId: product.id,
//                 optionId: selectedPoints,
//                 amount: parseInt(selectedPoints)
//             }, {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': authToken // Incluir el token de autenticación en los encabezados
//                 }
//             });


//             if (response.ok) {
//                 alert('Puntos canjeados exitosamente');
//             } else {
//                 const errorMessage = await response.text();
//                 alert(`Error: ${errorMessage}`);
//             }
//         } catch (error) {
//             console.error('Error al canjear puntos:', error);
//             alert('Error al canjear puntos');
//         }
//     };


//     if (!product) {
//         return <div className="text-center text-xl">Producto no encontrado</div>;
//     }

//     return (
//         <Layout>
//             <Navbar />
//             <div className="bg-white mt-52 shadow-md rounded-lg max-w-4xl mx-auto p-6 my-8">
//                 <div className="flex flex-col lg:flex-row">
//                     <div className="flex-none lg:w-1/3">
//                         <img src={product.imageSrc} alt={product.title} className="rounded-lg" />
//                     </div>
//                     <div className="lg:w-2/3 lg:pl-10">
//                         <h1 className="text-3xl font-bold mb-2">{product.title}</h1>
//                         <p className="text-xl font-semibold mb-4">{product.description}</p>
//                         <div className="space-y-2">
//                             <h2 className="text-lg font-semibold text-gray-700">DESCRIPCIÓN DEL PRODUCTO</h2>
//                             <p>Las giftcard deben ser utilizadas en su totalidad.</p>
//                             <p>La propina no está incluida</p>
//                             <p>No aplica sobre descuentos.</p>
//                         </div>
//                         <div className="mt-6">
//                             <label htmlFor="points" className="block text-sm font-medium text-gray-700">Seleccione puntos a canjear</label>
//                             <select
//                                 id="points"
//                                 name="points"
//                                 value={selectedPoints}
//                                 onChange={handlePointsChange}
//                                 className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
//                             >
//                                 <option value="Seleccione">Seleccione puntos a canjear</option>
//                                 <option value="30000">30,000</option>
//                                 <option value="50000">50,000</option>
//                                 <option value="100000">100,000</option>
//                                 <option value="200000">200,000</option>
//                             </select>
//                         </div>
//                         <button
//                             onClick={handleRedeem}
//                             className="mt-4 w-64 mx-auto bg-red-600 text-white text-sm py-1 px-2 rounded-md hover:bg-red-500 transition duration-300"
//                         >
//                             CANJEAR LITROS
//                         </button>
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </Layout>
//     );
// };

// export default ProductDetail;

import React, { useState, useEffect } from 'react';
import Layout from '../../hocs/layout/Layout';
import Navbar from '../../components/navigation/Navbar';
import Footer from '../../components/navigation/Footer';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

const apiUrl = process.env.REACT_APP_API_URL;
const webUrl = process.env.REACT_APP_WEB_URL;

const ProductDetail = () => {
    let { id } = useParams();
    const [product, setProduct] = useState(null);
    const [selectedPoints, setSelectedPoints] = useState('');
    const [selectedOptionId, setSelectedOptionId] = useState('');
    const [inputQuantity, setInputQuantity] = useState('');
    const [productValue, setProductValue] = useState('');

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${apiUrl}/products/${id}`);
                setProduct(response.data);
            } catch (error) {
                console.error('Error fetching product:', error);
                setProduct(null);
            }
        };

        fetchProduct();
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handlePointsChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedPoints(selectedValue);
        if (product.type === 'selectable') {
            const selectedOption = product.options.find(option => option.value.toString() === selectedValue);
            setSelectedOptionId(selectedOption ? selectedOption._id : '');
        }
    };

    const handleQuantityChange = (e) => {
        setInputQuantity(e.target.value);
    };

    const handleRedeem = async () => {
        if (product.type === 'selectable' && (!selectedPoints || selectedPoints === "Seleccione")) {
            Swal.fire('Error', 'Seleccione una cantidad de litros a canjear', 'error');
            return;
        }

        if (product.type === 'open' && (!inputQuantity || inputQuantity < product.quantityRange.min || inputQuantity > product.quantityRange.max)) {
            Swal.fire('Error', `Ingrese una cantidad entre ${product.quantityRange.min} y ${product.quantityRange.max}`, 'error');
            return;
        }

        const userId = localStorage.getItem('userId');
        const authToken = localStorage.getItem('token');

        if (!userId) {
            Swal.fire('Error', 'Usuario no autenticado', 'error');
            return;
        }

        if (!authToken) {
            Swal.fire('Error', 'Token de autenticación no encontrado', 'error');
            return;
        }

        const confirmResult = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¿Quieres canjear los litros?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, canjear',
            cancelButtonText: 'Cancelar'
        });

        if (!confirmResult.isConfirmed) {
            return;
        }

        try {
            const amount = product.type === 'normal' ? product.value : parseInt(product.type === 'selectable' ? selectedPoints : inputQuantity);
            const data = {
                userId,
                productId: product._id,
                optionId: selectedOptionId,
                amount
            };

            const response = await axios.post(`${apiUrl}/points/deduct`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authToken
                }
            });

            if (response.status === 200) {
                Swal.fire('Éxito', 'litros canjeados exitosamente', 'success');
            } else if (response.status === 400 && response.data === 'Insufficient balance') {
                Swal.fire('Error', 'Litros insuficientes para realizar el canje', 'error');
            } else {
                Swal.fire('Error', response.data, 'error');
            }
        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data === 'Insufficient balance') {
                Swal.fire('Error', 'Litros insuficientes para realizar el canje', 'error');
            } else {
                console.error('Error al canjear litros:', error);
                Swal.fire('Error', 'Error al canjear litros', 'error');
            }
        }
    };

    if (!product) {
        return <div className="text-center text-xl">Producto no encontrado</div>;
    }

    return (
        <Layout>
            <Navbar />
            <div className="bg-white mt-52 shadow-md rounded-lg max-w-4xl mx-auto p-6 my-8">
                <div className="flex flex-col lg:flex-row">
                    <div className="flex-none lg:w-1/3">
                        <img src={`${webUrl}/imagenes/${product.image}`} alt={product.name} className="rounded-lg" />
                    </div>
                    <div className="lg:w-2/3 lg:pl-10">
                        <h1 className="text-3xl font-bold mb-2">{product.name}</h1>
                        <p className="text-xl font-semibold mb-4">{product.description}</p>
                        <div className="space-y-2">
                            <h2 className="text-lg font-semibold text-gray-700">DESCRIPCIÓN DEL PRODUCTO</h2>
                            <p>Las giftcard deben ser utilizadas en su totalidad.</p>
                            <p>La propina no está incluida</p>
                            <p>No aplica sobre descuentos.</p>
                        </div>
                        {product.type === 'selectable' && (
                            <div className="mt-6">
                                <label htmlFor="points" className="block text-sm font-medium text-gray-700">Seleccione litros a canjear</label>
                                <select
                                    id="points"
                                    name="points"
                                    value={selectedPoints}
                                    onChange={handlePointsChange}
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                                >
                                    <option value="Seleccione">Seleccione litros a canjear</option>
                                    {product.options.map(option => (
                                        <option key={option._id} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {product.type === 'open' && (
                            <div className="mt-6">
                                <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">Ingrese la cantidad a canjear</label>
                                <input
                                    id="quantity"
                                    name="quantity"
                                    type="number"
                                    value={inputQuantity}
                                    onChange={handleQuantityChange}
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                                    min={product.quantityRange.min}
                                    max={product.quantityRange.max}
                                />
                                <p className="text-sm text-gray-500">Cantidad permitida: entre {product.quantityRange.min} y {product.quantityRange.max}</p>
                            </div>
                        )}
                        {product.type === 'normal' && (
                            <div className="mt-6">
                                <p className="text-lg font-semibold text-gray-700">Valor del producto: {product.value}</p>
                            </div>
                        )}
                        <button
                            onClick={handleRedeem}
                            className="mt-4 w-64 mx-auto bg-red-600 text-white text-sm py-1 px-2 rounded-md hover:bg-red-500 transition duration-300"
                        >
                            CANJEAR LITROS
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    );
};

export default ProductDetail;
