import { useEffect } from "react"
import Navbar from '../../components/navigation/Navbar'
import Footer from '../../components/navigation/Footer'
import Layout from '../../hocs/layout/Layout'
import WhiteCardCarousel from "../../components/catalogo/WhiteCardCarousel"

function Catalogo() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      return (
        <Layout>
          <Navbar />
          <div data-scroll-section className="pt-28">
          <WhiteCardCarousel />
          </div>
          <Footer />
        </Layout>
      )
}

export default Catalogo