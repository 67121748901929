import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PromocionesVigentes from './PromocionesVigentes'

function PromocionesVigentesList({ section_title }) {

    const [promotions, setPromotions] = useState([]);

    useEffect(() => {
        const fetchPromotions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/promociones`);
                setPromotions(response.data);
            } catch (error) {
                console.error('Error fetching promotions:', error);
            }
        };

        fetchPromotions();
    }, []);


    return (
        <>
            <div className="relative bg-gray-90 px-4  pb-20 sm:px-6 lg:px-8  lg:pb-28 -mt-20">
                <div className="absolute inset-0">
                    <div className="h-1/3 bg-gray-90 sm:h-2/3" />
                </div>
                <div className="relative mx-auto lg:mx-12 max-w-full">
                    <div className="text-bg-black">
                        <h2 className='text-3xl font-bold relative mt-72 -ml-13 after:content-[""] after:block after:border-b-4 after:border-red-600 after:w-[5%]'>{section_title}</h2>

                        {/* <br />
                        <p className='text -ml-10'>
                            Revisa todas nuestras promociones<br />
                            y mantente al dia de lo que tenemos para ti.
                        </p> */}
                    </div>

                    <div className="mx-auto mt-14  grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
                        {promotions.map((promo, index) => (
                            <PromocionesVigentes key={index} data={promo} index={index} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PromocionesVigentesList