import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import logo_donbife from '../../assets/img/Home-CardCarousel/02_donbife.png'
import logo_buinzoo from '../../assets/img/Home-CardCarousel/03_buinzoo.png'
import logo_juicylucy from '../../assets/img/Home-CardCarousel/05_juicylucy.png'
import logo_santabrasa from '../../assets/img/Home-CardCarousel/04_santabrasa.png'
import logo_elguaranguito from '../../assets/img/Home-CardCarousel/01_elguaranguito.png'
import logo_giftcardcencosud from '../../assets/img/Home-CardCarousel/Gift-Card-Cencosud-200.000.png'
import logo_giftcardsmu from '../../assets/img/Home-CardCarousel/Gift-Card-Smu.png'

const apiUrl = process.env.REACT_APP_API_URL;
const webUrl = process.env.REACT_APP_WEB_URL;



const blog = [
    {
        id: 1,
        title: 'Gift Card Don Bife',
        imageSrc: logo_donbife,
        description:
            'Desde 5.000 Litros',
    },
    {
        id: 2,
        title: 'Entrada para el Buin Zoo',
        imageSrc: logo_buinzoo,
        description:
            '8.184 Litros',
    },
    {
        id: 3,
        title: 'Gift Card Juicy Lucy',
        imageSrc: logo_juicylucy,
        description:
            'Desde 5.000 Litros',
    },
    {
        id: 4,
        title:
            'Gift Card Santa Brasa',
        imageSrc: logo_santabrasa,
        description:
            'Desde 5.000 Litros',
    },
    {
        id: 5,
        title:
            'Gift Card EL Guaranguito',
        imageSrc: logo_elguaranguito,
        description:
            'Desde 5.000 Litros',
    },
    {
        id: 6,
        title:
            'Gift Card Cencosud',
        imageSrc: logo_giftcardcencosud,
        description:
            'Desde 5.000 Litros',
    },
    {
        id: 7,
        title:
            'Gift Card SMU',
        imageSrc: logo_giftcardsmu,
        description:
            'Desde 5.000 Litros',
    },
];

function CardCarousel() {

    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${apiUrl}/products`);
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const carouselProducts = [...products, ...products];

    const handleViewMore = () => {
        navigate('/catalogo');
    };

    return (


        <div className='relative bg-black'>
            {/* Este div contendrá el título y la descripción y estará por encima del carrusel */}
            <div className='text-white p-4 absolute top-0 left-0 right-0 z-10 bg-opacity-90 bg-black flex justify-between items-center'>
                <div className='flex-grow pl-5'>
                    <h2 className='text-3xl font-bold relative mt-20 after:content-[""] after:block after:border-b-4 after:border-red-600 after:w-[5%]'>
                        Catálogo
                    </h2>
                    <br />
                    <p className='text mt-2'>
                        Revisa todo el catálogo y conoce los productos<br />
                        que puedes obtener con tus Litros acumulados.
                    </p>
                </div>
                <div className='pr-5'>
                    <button
                        className='bg-red-600 text-white font-medium mt-12 py-2 px-4 rounded-md hover:bg-red-500 transition duration-300'
                        onClick={handleViewMore}
                    >
                        Ver Más &gt;
                    </button>
                </div>
            </div>
            <br />
            <br />
            <br />


            {/* Este div contendrá el carousel */}
            <div className='card-carousel py-28 flex overflow-auto pt-[120px] mt-20'>
                <div className='my-5 mx-auto max-w-full'>
                    <div className='flex overflow-hidden min-w-full'>
                        <div className='flex whitespace-nowrap animate-scroll'>
                            {carouselProducts.map((item, index) => (
                                <Link to={`/detail/${item._id}`} key={index} className='block'>
                                    <div key={index} className='h-full w-[400px] m-2 flex-shrink-0 cursor-pointer'>
                                        <div className='rounded-3xl overflow-hidden mb-4 relative' style={{ width: '400px', height: '200px' }}>
                                            <img src={`${webUrl}/imagenes/${item.image}`} alt={item.name} className="object-cover w-full h-full" />
                                        </div>
                                        <div className='px-4 flex gap-4'>
                                            <div className='flex flex-col gap-2 w-full'>
                                                <h3 className='text-lg font-bold text-white   leading-7 whitespace-normal'>
                                                    {item.name}
                                                </h3>
                                                <p className='text-sm text-slate-500 font-semibold'>
                                                    {item.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default CardCarousel