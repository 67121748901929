// import { Popover, Transition } from '@headlessui/react'
// import { useState, Fragment, useEffect } from 'react'
// import { NavLink, Link } from 'react-router-dom'
// import DotLoader from 'react-spinners/DotLoader'
// import axios from 'axios';
// import { jwtDecode } from 'jwt-decode';
// import logo_litros from '../../assets/img/Navbar/logo_litros.png';
// import logo1 from '../../assets/img/Navbar/logo_cocacola_andina.png';
// import logo2 from '../../assets/img/Navbar/logo_diageo.png';
// import logo3 from '../../assets/img/Navbar/logo_cooperativa_capel.png';
// import logo4 from '../../assets/img/Navbar/logo_abinbev.png';
// import logo5 from '../../assets/img/Navbar/logo_guallarauco.png';

// const apiUrl = process.env.REACT_APP_API_URL;

// const solutions = [
//   {
//     name: 'Mi Cuenta',
//     description: 'Keep track of your growth',
//     href: '/mi_cuenta',
//     icon: IconThree,
//   },
//   {
//     name: 'Como Participar',
//     description: 'Measure actions your users take',
//     href: '/como_participar',
//     icon: IconOne,
//   },
//   {
//     name: 'Catalogo',
//     description: 'Create your own targeted content',
//     href: '/catalogo',
//     icon: IconTwo,
//   },
//   {
//     name: 'Promociones Vigentes',
//     description: 'Keep track of your growth',
//     href: '/promociones_vigentes',
//     icon: IconThree,
//   },
//   {
//     name: 'Noticias',
//     description: 'Keep track of your growth',
//     href: '/noticias',
//     icon: IconThree,
//   },
//   {
//     name: 'Necesitas Ayuda',
//     description: 'Keep track of your growth',
//     href: '/necesitas_ayuda',
//     icon: IconThree,
//   },
// ]

// function Navbar() {
//   const [loading, setLoading] = useState(true)
//   const [userName, setUserName] = useState('');
//   const [liters, setLiters] = useState(0);

//   const logos = [logo1, logo2, logo3, logo4, logo5];

//   window.onscroll = function () { scrollFunction() }

//   function scrollFunction() {
//     if (document.getElementById('navbar')) {
//       if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
//         document.getElementById('navbar').classList.add('shadow-navbar');
//         document.getElementById('navbar').classList.add('bg-black');
//       } else {
//         document.getElementById('navbar').classList.remove('shadow-navbar');
//         // document.getElementById('navbar').classList.remove('bg-black');
//       }
//     }
//   }


//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const token = localStorage.getItem('token');
//         if (!token) {
//           throw new Error("Token not found");
//         }

//         const decodedToken = jwtDecode(token);
//         const userId = decodedToken.user._id;

//         // Set user data from token
//         setUserName(`${decodedToken.user.nombres} ${decodedToken.user.apellidos}`);

//         // Fetch liters balance
//         const response = await axios.get(`${apiUrl}/points/${userId}/balance`, {
//           headers: {
//             'Authorization': token,
//             'Content-Type': 'application/json'
//           }
//         });

//         setLiters(response.data.pointsAccount.balance);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//         setLoading(false);
//       }
//     };

//     fetchUserData();
//   }, []);

//   return (
//     <nav data-scroll data-scroll-id="hey" id='navbar' className='navbar w-full py-6 top-0 transition duration-500 ease-in-out z-40 fixed bg-black'>
//       <div className='flex flex-wrap justify-between items-center px-4 md:px-10'>
//         <div className='flex'>
//           {logos.map((logo, index) => (
//             <img key={index} src={logo} alt={`Logo ${index + 1}`} className="h-6 mr-10" /> // Ajusta las clases según sea necesario
//           ))}
//         </div>
//         <div className='flex flex-col items-center text-center mt-2'>
//           <span className='text-white text-sm md:text-lg'>Hola {userName}</span>
//           <span className='text-white font-bold text-xs md:text-sm'>
//             TIENES <span className='text-red-600'>{liters}</span> LITROS
//           </span>
//         </div>
//       </div>
//       <div className='px-4 pt-6 sm:px-6'>
//         <div className="-ml-4 -mt-2 hidden lg:flex flex-wrap items-center justify-between sm:flex-nowrap md:px-4 px-2">
//           <Link to='/home' className='ml-4 mt-2'>
//             <img src={logo_litros} width={160} height={160} className='' />
//           </Link>
//           <div className='ml-4 mt-4 flex-shrink-0'>
//             <NavLink
//               to='/como_participar'
//               className='text-base inline-flex font-semibold leading-6 text-gray-500 border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4'
//             >
//               ¿CÓMO PARTICIPAR?
//             </NavLink>
//             <NavLink
//               to='/catalogo'
//               className='text-base inline-flex font-semibold leading-6 text-gray-500 border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4'
//             >
//               CATÁLOGO
//             </NavLink>
//             <NavLink
//               to='/promociones_vigentes'
//               className='text-base inline-flex font-semibold leading-6 text-gray-500 border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4'
//             >
//               PROMOCIONES VIGENTES
//             </NavLink>
//             <NavLink
//               to='/noticias'
//               className='text-base inline-flex font-semibold leading-6 text-gray-500 border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4'
//             >
//               NOTICIAS
//             </NavLink>
//             <NavLink
//               to='/necesitas_ayuda'
//               className='text-base inline-flex font-semibold leading-6 text-gray-500 border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4'
//             >
//               ¿NECESITAS AYUDA?
//             </NavLink>

//             <Link
//               to="/mi_cuenta"
//               className="inline-flex ml-12 items-center rounded-md border border-transparent px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-red-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
//             >
//               MI CUENTA
//               <DotLoader className="ml-3 -mr-1 h-5 w-5" loading={loading} size={20} color="#f2f2f2" />
//             </Link>
//           </div>
//         </div>
//         <div className='-ml-4 -mt-2 lg:hidden flex flex-wrap items-center justify-between sm:flex-nowrap md:px-14 px-2'>
//           <Link to='/home' className='ml-4 mt-2'>
//             <img src={logo_litros} width={160} height={160} className='' />
//           </Link>
//           <div className='ml-4 mt-2 flex-shrink-0'>
//             <Popover className='relative'>
//               {({ open }) => (
//                 <>
//                   <Popover.Button
//                     className={`${open
//                       ? 'bg-black text-red-500'
//                       : 'bg-black-600 text-red-500/90'
//                       } focus:ring-none focus:outline-none bg-black custom-radius p-2 rounded-md`}
//                   >
//                     {open ? (
//                       <i className='bx bx-x text-4xl'></i>
//                     ) : (
//                       <i className='bx bx-menu text-4xl'></i>
//                     )}
//                   </Popover.Button>

//                   <Transition
//                     as={Fragment}
//                     enter='transition ease-out duration-200'
//                     enterFrom='opacity-0 translate-y-1'
//                     enterTo='opacity-100 translate-y-0'
//                     leave='transition ease-in duration-150'
//                     leaveFrom='opacity-100 translate-y-0'
//                     leaveTo='opacity-0 translate-y-1'
//                   >
//                     <Popover.Panel className="absolute -left-32 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
//                       <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
//                         <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
//                           {solutions.map((item) => (
//                             <Link
//                               key={item.name}
//                               to={item.href}
//                               className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
//                             >
//                               <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
//                                 <item.icon aria-hidden="true" />
//                               </div>
//                               <div className="ml-4">
//                                 <p className="text-sm font-medium text-gray-900">
//                                   {item.name}
//                                 </p>
//                                 <p className="text-sm text-gray-500">
//                                   {item.description}
//                                 </p>
//                               </div>
//                             </Link>
//                           ))}
//                         </div>
//                         <div className="bg-gray-50 p-4">
//                           <a
//                             href="##"
//                             className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
//                           >
//                             <span className="flex items-center">
//                               <span className="text-sm font-medium text-gray-900">
//                                 Documentation
//                               </span>
//                             </span>
//                             <span className="block text-sm text-gray-500">
//                               Start integrating products and tools
//                             </span>
//                           </a>
//                         </div>
//                       </div>
//                     </Popover.Panel>
//                   </Transition>
//                 </>
//               )}
//             </Popover>
//           </div>
//         </div>
//       </div>
//     </nav>
//   )
// }

// export default Navbar;

// function IconOne() {
//   return (
//     <svg
//       width="48"
//       height="48"
//       viewBox="0 0 48 48"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <rect width="48" height="48" rx="8" fill="#FFD2D2" />  // Fondo más claro
//       <path
//         d="M24 11L35.2583 17.5V30.5L24 37L12.7417 30.5V17.5L24 11Z"
//         stroke="#FF0000"  // Rojo estándar
//         strokeWidth="2"
//       />
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M16.7417 19.8094V28.1906L24 32.3812L31.2584 28.1906V19.8094L24 15.6188L16.7417 19.8094Z"
//         stroke="#FF0000"  // Rojo estándar
//         strokeWidth="2"
//       />
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M20.7417 22.1196V25.882L24 27.7632L27.2584 25.882V22.1196L24 20.2384L20.7417 22.1196Z"
//         stroke="#FF0000"  // Rojo estándar
//         strokeWidth="2"
//       />
//     </svg>
//   )
// }


// function IconTwo() {
//   return (
//     <svg
//       width="48"
//       height="48"
//       viewBox="0 0 48 48"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <rect width="48" height="48" rx="8" fill="#FFD2D2" />  // Fondo más claro
//       <path
//         d="M28.0413 20L23.9998 13L19.9585 20M32.0828 27.0001L36.1242 34H28.0415M19.9585 34H11.8755L15.9171 27"
//         stroke="#FF0000"  // Rojo estándar
//         strokeWidth="2"
//       />
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M18.804 30H29.1963L24.0001 21L18.804 30Z"
//         stroke="#FF0000"  // Rojo estándar
//         strokeWidth="2"
//       />
//     </svg>
//   )
// }


// function IconThree() {
//   return (
//     <svg
//       width="48"
//       height="48"
//       viewBox="0 0 48 48"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <rect width="48" height="48" rx="8" fill="#FFD2D2" />  // Fondo más claro
//       <rect x="13" y="32" width="2" height="4" fill="#FF0000" />  // Rojo estándar
//       <rect x="17" y="28" width="2" height="8" fill="#FF0000" />  // Rojo estándar
//       <rect x="21" y="24" width="2" height="12" fill="#FF0000" />  // Rojo estándar
//       <rect x="25" y="20" width="2" height="16" fill="#FF0000" />  // Rojo estándar
//       <rect x="29" y="16" width="2" height="20" fill="#FF0000" />  // Rojo más fuerte
//       <rect x="33" y="12" width="2" height="24" fill="#FF0000" />  // Rojo más fuerte
//     </svg>
//   )
// }


import { Popover, Transition } from '@headlessui/react'
import { useState, Fragment, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import DotLoader from 'react-spinners/DotLoader'
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import logo_litros from '../../assets/img/Navbar/logo_litros.png';
import logo1 from '../../assets/img/Navbar/logo_cocacola_andina.png';
import logo2 from '../../assets/img/Navbar/logo_diageo.png';
import logo3 from '../../assets/img/Navbar/logo_cooperativa_capel.png';
import logo4 from '../../assets/img/Navbar/logo_abinbev.png';
import logo5 from '../../assets/img/Navbar/logo_guallarauco.png';

const apiUrl = process.env.REACT_APP_API_URL;

const solutions = [
  {
    name: 'Mi Cuenta',
    description: 'Keep track of your growth',
    href: '/mi_cuenta',
    icon: IconThree,
  },
  {
    name: 'Como Participar',
    description: 'Measure actions your users take',
    href: '/como_participar',
    icon: IconOne,
  },
  {
    name: 'Catalogo',
    description: 'Create your own targeted content',
    href: '/catalogo',
    icon: IconTwo,
  },
  {
    name: 'Promociones Vigentes',
    description: 'Keep track of your growth',
    href: '/promociones_vigentes',
    icon: IconThree,
  },
  // {
  //   name: 'Noticias',
  //   description: 'Keep track of your growth',
  //   href: '/noticias',
  //   icon: IconThree,
  // },
  {
    name: 'Necesitas Ayuda',
    description: 'Keep track of your growth',
    href: '/necesitas_ayuda',
    icon: IconThree,
  },
]

function Navbar() {
  const [loading, setLoading] = useState(true)
  const [userName, setUserName] = useState('');
  const [liters, setLiters] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);


  const logos = [logo1, logo2, logo3, logo4, logo5];

  window.onscroll = function () { scrollFunction() }

  function scrollFunction() {
    if (document.getElementById('navbar')) {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        document.getElementById('navbar').classList.add('shadow-navbar');
        document.getElementById('navbar').classList.add('bg-black');
      } else {
        document.getElementById('navbar').classList.remove('shadow-navbar');
        // document.getElementById('navbar').classList.remove('bg-black');
      }
    }
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);

        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error("Token not found");
        }

        const decodedToken = jwtDecode(token);
        const userId = decodedToken.user._id;

        setUserName(`${decodedToken.user.nombres} ${decodedToken.user.apellidos}`);

        const response = await axios.get(`${apiUrl}/points/${userId}/balance`, {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        });

        setLiters(response.data.pointsAccount.balance);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(true);
      }
    };

    fetchUserData();

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);


  return (
    <nav data-scroll data-scroll-id="hey" id='navbar' className={`navbar w-full py-6 top-0 transition duration-500 ease-in-out z-40 fixed backgroundred ${showNavbar ? 'translate-y-0' : '-translate-y-full'}`}>
      <div className="flex flex-wrap justify-between items-center px-10 lg:px-10">
        <div className="hidden  sm:flex items-center  ">
          {logos.map((logo, index) => (
            <img key={index} src={logo} alt={`Logo ${index + 1}`} className="h-4 md:h-6 mr-10" /> // Ajusta las clases según sea necesario
          ))}
        </div>
        <div className='flex flex-cfol md:items-end text-center mt-2 '>
          <span className='text-white uppercase text-sm md:text-sm'>Hola {userName}</span>
          <span className='text-white font-bold text-xs md:text-sm'>
            TIENES <span className='text-slate-950'>{liters}</span> LITROS
          </span>
        </div>
      </div>
      <div className="px-4 pt-6 sm:px-6">
        <div className="-ml-4 -mt-2 hidden lg:flex flex-wrap items-center justify-between sm:flex-nowrap md:px-4 px-2">
          <Link to='/home' className="ml-4 mt-2">
            <img
              src={logo_litros}
              width={190}
              height={190}
              className=""
            />
          </Link>
          <div className="ml-4 mt-4 flex-shrink-0">
            <NavLink to='/como_participar' className="text-md inline-flex font-semibold leading-6 text-white border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4">¿CÓMO PARTICIPAR?</NavLink>
            <NavLink to='/catalogo' className="text-md inline-flex font-semibold leading-6 text-white border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4">CATÁLOGO</NavLink>
            <NavLink to='/promociones_vigentes' className="text-md inline-flex font-semibold leading-6 text-white border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4">PROMOCIONES</NavLink>
             <NavLink to="https://litrosdefelicidad.cl/manual_litros_de_felicidad.pdf" target='_blank'  className="text-md inline-flex font-semibold leading-6 text-white border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4">MANUAL DE USO</NavLink> 
            <NavLink to='/necesitas_ayuda' className="text-md inline-flex font-semibold leading-6 text-white border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4">¿NECESITAS AYUDA?</NavLink>

            <Link
              to="/mi_cuenta"
              className="inline-flex ml-12 items-center rounded-md border border-transparent px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-red-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
            >
              MI CUENTA
              <DotLoader className="ml-3 -mr-1 h-5 w-5" loading={loading} size={20} color="#f2f2f2" />
            </Link>

          </div>
        </div>
        <div className="-ml-4 -mt-2 lg:hidden flex flex-wrap items-center justify-between sm:flex-nowrap md:px-14 px-2">
          <Link to='/home' className="ml-4 mt-2">
            <img
              src={logo_litros}
              width={160}
              height={160}
              className=""
            />
          </Link>
          <div className="ml-4 mt-2 flex-shrink-0">

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={`
                      ${open ? 'bg-black text-red-500' : 'bg-black-600 text-red-500/90'}
                      focus:ring-none focus:outline-none bg-black custom-radius 
                    `}
                  >
                    {
                      open ?
                        <i className='bx bx-x text-4xl'></i>
                        :
                        <i className='bx bx-menu text-4xl'></i>
                    }
                  </Popover.Button>


                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute -left-32 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                          {solutions.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                            >
                              <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                <item.icon aria-hidden="true" />
                              </div>
                              <div className="ml-4">
                                <p className="text-sm font-medium text-gray-900">
                                  {item.name}
                                </p>
                                <p className="text-sm text-white">
                                  {item.description}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                        <div className="bg-gray-50 p-4">
                          <a
                            href="##"
                            className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          >
                            <span className="flex items-center">
                              <span className="text-sm font-medium text-gray-900">
                                Documentation
                              </span>
                            </span>
                            <span className="block text-sm text-white">
                              Start integrating products and tools
                            </span>
                          </a>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;

function IconOne() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFD2D2" />  // Fondo más claro
      <path
        d="M24 11L35.2583 17.5V30.5L24 37L12.7417 30.5V17.5L24 11Z"
        stroke="#FF0000"  // Rojo estándar
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7417 19.8094V28.1906L24 32.3812L31.2584 28.1906V19.8094L24 15.6188L16.7417 19.8094Z"
        stroke="#FF0000"  // Rojo estándar
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7417 22.1196V25.882L24 27.7632L27.2584 25.882V22.1196L24 20.2384L20.7417 22.1196Z"
        stroke="#FF0000"  // Rojo estándar
        strokeWidth="2"
      />
    </svg>
  )
}


function IconTwo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFD2D2" />  // Fondo más claro
      <path
        d="M28.0413 20L23.9998 13L19.9585 20M32.0828 27.0001L36.1242 34H28.0415M19.9585 34H11.8755L15.9171 27"
        stroke="#FF0000"  // Rojo estándar
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.804 30H29.1963L24.0001 21L18.804 30Z"
        stroke="#FF0000"  // Rojo estándar
        strokeWidth="2"
      />
    </svg>
  )
}


function IconThree() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFD2D2" />  // Fondo más claro
      <rect x="13" y="32" width="2" height="4" fill="#FF0000" />  // Rojo estándar
      <rect x="17" y="28" width="2" height="8" fill="#FF0000" />  // Rojo estándar
      <rect x="21" y="24" width="2" height="12" fill="#FF0000" />  // Rojo estándar
      <rect x="25" y="20" width="2" height="16" fill="#FF0000" />  // Rojo estándar
      <rect x="29" y="16" width="2" height="20" fill="#FF0000" />  // Rojo más fuerte
      <rect x="33" y="12" width="2" height="24" fill="#FF0000" />  // Rojo más fuerte
    </svg>
  )
}