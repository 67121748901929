// import React from 'react'

// import { Link } from "react-router-dom"

// function BlogSection({ data, index }) {
//     return (
//         <Link
//             to='/cases/id'
//             onMouseEnter={() => {
//                 const title_element = document.getElementById(index)
//                 title_element.classList.add('text-orange-500')
//                 const img = document.getElementById(data.id)
//                 img.classList.add('object-scale-down')
//             }}
//             onMouseLeave={() => {
//                 const title_element = document.getElementById(index)
//                 title_element.classList.remove('text-orange-500')
//                 const img = document.getElementById(data.id)
//                 img.classList.remove('object-scale-down')
//             }}

//             className="flex flex-col overflow-hidden  rounded-lg shadow-lg">
//             <div className="flex-shrink-0">
//                 <img id={data.id} className="h-96 w-full transition duration-400 ease-in-out object-cover" src={data.imageUrl} alt="" />
//             </div>
//             <div className="flex flex-1 flex-col justify-between bg-white p-6">
//                 <div className="flex-1">
//                     <p className="text-xl font-medium text-gray-800">
//                         <a href={data.category.href} className="hover:underline">
//                             {data.category.name}
//                         </a>
//                     </p>
//                     <a href={data.href} className="mt-2 block">
//                         <p id={index} className="lg:text-4xl pt-4 pb-6 text-2xl font-semibold transition duration-400 ease-in-out text-gray-900">{data.title}</p>
//                         <p className="mt-3 text-2xl space-y-2 leading-9 text-gray-500">{data.description}</p>
//                     </a>
//                 </div>
//             </div>
//         </Link>
//     )
// }

// export default BlogSection

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

// const posts = [
//     {
//         title: 'Boost your conversion rate',
//         href: '#',
//         category: { name: 'Article', href: '#' },
//         description:
//             'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.',
//         date: 'Mar 16, 2020',
//         datetime: '2020-03-16',
//         imageUrl:
//             'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
//         readingTime: '6 min',
//         author: {
//             name: 'Roel Aufderehar',
//             href: '#',
//             imageUrl:
//                 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//         },
//     },
//     {
//         title: 'How to use search engine optimization to drive sales',
//         href: '#',
//         category: { name: 'Video', href: '#' },
//         description:
//             'Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.',
//         date: 'Mar 10, 2020',
//         datetime: '2020-03-10',
//         imageUrl:
//             'https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
//         readingTime: '4 min',
//         author: {
//             name: 'Brenna Goyette',
//             href: '#',
//             imageUrl:
//                 'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//         },
//     },
//     {
//         title: 'Improve your customer experience',
//         href: '#',
//         category: { name: 'Case Study', href: '#' },
//         description:
//             'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.',
//         date: 'Feb 12, 2020',
//         datetime: '2020-02-12',
//         imageUrl:
//             'https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
//         readingTime: '11 min',
//         author: {
//             name: 'Daniela Metz',
//             href: '#',
//             imageUrl:
//                 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//         },
//     },
// ]

function BlogSection() {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchNoticias = async () => {
            try {
                const response = await axios.get(`${apiUrl}/noticia/noticias`);
                setPosts(response.data);
            } catch (error) {
                console.error('Error al obtener las noticias:', error);
            }
        };

        fetchNoticias();
    }, []);

    return (
        <div className="relative bg-gray-50 px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
            <div className="absolute inset-0">
                <div className="h-1/3 bg-white sm:h-2/3" />
            </div>
            <div className="relative mx-auto max-w-7xl">
                {/* <div className='flex-grow pl-5'>
                    <h2 className='text-3xl font-bold relative mt-5 after:content-[""] after:block after:border-b-4 after:border-red-600 after:w-[5%]'>
                        Noticias
                    </h2>
                    <br />
                    <p className='text mt-2'>
                        Conoce nuestras ultimas noticias y mantente al dia<br />
                        con todas las novedades que tenemos para ti.
                    </p>
                </div> */}
                <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
                    {posts.map((post) => (
                        <div key={post._id} className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                            <div className="flex-shrink-0">
                                <img className="h-48 w-full object-cover" src={post.imagen} alt="" />
                            </div>
                            <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                <div className="flex-1">
                                    <a href="#" className="mt-2 block">
                                        <p className="text-xl font-semibold text-gray-900">{post.titulo}</p>
                                        <p className="mt-3 text-base text-gray-500">{post.texto}</p>
                                    </a>
                                </div>
                                <div className="mt-6 flex items-center">
                                    <div className="flex-shrink-0">
                                        <a href="#">
                                            <span className="sr-only">Author</span>
                                            {/* <img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt="" /> */}
                                        </a>
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-gray-900">
                                            <a href="#" className="hover:underline">
                                                {post.autor} {/* Assuming you have an author field */}
                                            </a>
                                        </p>
                                        <div className="flex space-x-1 text-sm text-gray-500">
                                            <time dateTime={post.fecha}>{post.fecha}</time> {/* Assuming you have a date field */}
                                            <span aria-hidden="true">&middot;</span>
                                            <span>{post.tiempoLectura} read</span> {/* Assuming you have a readingTime field */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BlogSection