import { useEffect } from "react"
import Navbar from '../../components/navigation/Navbar'
import Footer from '../../components/navigation/Footer'
import Layout from '../../hocs/layout/Layout'
// import BlogSectionList from "../../components/home/BlogSectionList"
import BlogSection from "../../components/noticias/BlogSection"




function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Layout>
            <Navbar />
            <div data-scroll-section className="pt-28">
                {/* <BlogSectionList /> */}
                {/* <BlogSection /> */}
            </div>
            <Footer />
        </Layout>
    )
}

export default Home